import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Box, Typography, Link, useTheme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.primary
  }
}))

const Header = ({ siteTitle }) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box bgcolor="primary.main">
      <Box mx="auto" py={4}>
        <Typography align="center" variant="h4" component="h1">
          <Link
            component={GatsbyLink}
            to="/"
            underline="none"
            className={classes.link}
          >
            {siteTitle}
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default Header
